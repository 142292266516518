<template>
  <div class="loading">
    <img src="./loading.gif">
  </div>
</template>

<script>
import { loginApi } from '@/axios'
export default {
  data () {
    return {
      user: global.store.get('DATA_USER')
    }
  },
  methods: {
    // 交换token
    changeToken () {
      let url = loginApi + `admin/user/exchangeToken?type=${this.$route.query.plantType}&token=${this.$route.query.token}&exchangeType=1`
      this.$https.get(url).then(res => {
        if (res.code === 200) {
          this.getUserRead(res.data)
        }
      })
    },  
    // 用token获取登录信息
    getUserRead (token) {
      let url = loginApi + 'admin/user/read'
      let data = {
        token: token,
        type: 1
      }
      this.$https.post(url, data).then(res => {
        console.log('res', res)
        res.token = token
        let role = [1, 50, 143, 132] // 132运营 143、50营运 1管理员
        if (role.includes(res.role_id)) {
          global.store.set('DATA_USER', res)
          this.$router.push({ path: this.$route.query.router })
        } else {
          this.$message.error('权限不足，无法查看')
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.user) {
        vm.$router.push(to.query.router)
      } else {
        vm.changeToken()
      }
    })
  }
}
</script>
<style scoped>
  .loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  i {
    font-size: 800px;
    color: #1890FF;
  }
</style>
